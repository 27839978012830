import React,  {useState, useEffect} from 'react';
import manual from '../assets/AgilityRun.pdf';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import ReCaptcha from 'react-google-recaptcha';

function Links() {
    const [valCap, setValCap] = useState(null);
    useEffect(() => {
        // Function to load a script
        const loadScript = (url, id) => {
            const script = document.createElement('script');
            script.src = url;
            script.async = true;
            script.id = id;
            document.body.appendChild(script);
        };

        // Load the Mailchimp script
        loadScript('//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js', 'mc-validate-script');

        // Initialize Mailchimp variables
        window.fnames = new Array();
        window.ftypes = new Array();
        window.fnames[0]='EMAIL';
        window.ftypes[0]='email';
        window.fnames[1]='FNAME';
        window.ftypes[1]='text';
        window.fnames[5]='MASCOTA';
        window.ftypes[5]='text';
        window.fnames[2]='LNAME';
        window.ftypes[2]='text';
        window.fnames[3]='ADDRESS';
        window.ftypes[3]='address';
        window.fnames[4]='PHONE';
        window.ftypes[4]='phone';

        return () => {
            // Cleanup script when the component unmounts
            const script = document.getElementById('mc-validate-script');
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, []);


    return (
        <div className="container-fluid py-5">
            <div className="row justify-content-center">
                <div className="col-12 col-md-8">
                    <div className="text-center">
                        <a href="https://agilitypets.com/" target="_blank">
                            <img className="logo d-block m-auto" src={process.env.PUBLIC_URL + '/logo-agility.png'} alt="Logo" />
                        </a>
                    </div>
                </div>
                <div className="col-12 col-md-6 mt-4 justify-content-center">
                    <div >

                        <div className="col-12 my-3">
                            <a className="btn text-white d-block fw-bold" style={{ background: "#083f94", borderRadius: "10px" }} href="https://agilitypets.com/" target="_blank">🔗 ¡VISITA NUESTRA PÁGINA WEB!</a>
                        </div>
                        
                        <div className="col-12 my-3">
                            <a className="btn text-white d-block fw-bold"  style={{ background: "#083f94", borderRadius: "10px" }} href="https://agilitypets.com/blog/" target="_blank">🐕🐈 TIPS PARA EL CUIDADO DE TU PERRO/GATO</a>
                        </div>
                        
                        <div className="col-12 my-3" >
                            <a className="btn text-white d-block fw-bold " style={{ background: "#083f94", borderRadius: "10px" }} href="https://open.spotify.com/user/31dvaczejhpzjtjm236ayyumzv2m?si=Pq-DuTBeShCD3obKRsLWpw" target="_blank">🎧🎵 EXPLORÁ NUESTRAS PLAYLIST DE SPOTIFY</a>
                        </div>

                        <div className="col-12 my-3" >
                            <a className="btn text-white d-block fw-bold " style={{ background: "#083f94", borderRadius: "10px" }} href={manual} download="Manual_Agility_Run"target="_blank">📖 AGILITY+ RUN: MANUAL DE CONVIVENCIA</a>
                        </div>
                        
                    </div>
                    
                    <div className='col-12 py-2' style={{ background: "#083f94", borderRadius: "10px", textAlign:"center" }}>
                        <div id="mc_embed_shell">
                            <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css"/>
                                    <div id="mc_embed_signup" className='justify-content-center'>
                                        <form action="https://alican.us20.list-manage.com/subscribe/post?u=398535aafb68b07b55da693d6&amp;id=b8a73b9a00&amp;f_id=00a127e4f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
                                                <div id="mc_embed_signup_scroll" className='text-white'><h2>¡Unite a la comunidad  Agility! 🐾</h2>
                                                    <div class="mc-field-group"><label for="mce-EMAIL">
                                                        Email <span class="asterisk">*</span></label>
                                                        <input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="true" />
                                                    </div>
                                                    <div class="mc-field-group"><label for="mce-FNAME">
                                                        Nombre <span class="asterisk">*</span></label>
                                                        <input type="text" name="FNAME" class="required text" id="mce-FNAME" required="true" />
                                                    </div>
                                                    <div class="mc-field-group"><label for="mce-MASCOTA">
                                                        ¿Qué tipo de mascota tenés? <span class="asterisk">*</span></label>
                                                        
                                                        <select name="MASCOTA" id="mce-MASCOTA" required="">
                                                            <option value="PERRO">Perro</option>
                                                            <option value="GATO">Gato</option>
                                                            <option value="PERRO Y GATO">Perro y Gato</option>
                                                        </select>
                                                    </div>

                                                    <div id="mce-responses" class="clear">
                                                        <div class="response" id="mce-error-response" style={{display:"none"}}></div>
                            		                    <div class="response" id="mce-success-response" style={{display:"none"}}></div>
                            	                    </div>    
                                                    <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_398535aafb68b07b55da693d6_421c7e0453" tabindex="-1" value=""/>
                                                    </div>

                                <div class="clear">
                                    <ReCaptcha 
                                        sitekey="6LdREz4pAAAAAGah9hri27_nm3Xm-zKDg053R38P" 
                                        onChange={ver =>  setValCap(ver)}
                                    />
                                    <div className='py-2'></div>
                                    <button type="submit" disabled={ valCap ===  null}  name="subscribe" id="mc-embedded-subscribe" class="btn border btn-outline-light m-0">Suscribite</button></div>
                                </div>
                            </form>
                            </div>
                            </div>
                                                
                                        
                            </div>
                        </div>
                    </div>
                </div>
        
    );
}

export default Links;
