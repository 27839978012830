import logo from './logo.svg';
import './App.css'
import Links  from './logic/links.js';

function App() {
  return (
    
      <Links />
    
  );
}

export default App;
